import React from "react";
import { PrismicRichText } from "@prismicio/react";
import X from "../components/svg/X";

export default function WebsiteBanner({ data, isBanner, setIsBannerOpen }) {
  
  return (
    <>
      {data.body["0"].primary.isDisplayed && (
        <>
          {isBanner && (
            <div className="fixed top-0 z-50 py-2 text-white w-screen px-3 mx-auto bg-blue flex items-center">
              <div className="font-medium text-center bannerText ml-auto">
                <PrismicRichText field={data.body["0"].primary.text_banner.richText} />
              </div>

              <button
                onClick={() => setIsBannerOpen(false)}
                className="ml-auto"
                aria-label="Close Banner"
              >
                <X />
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
}
