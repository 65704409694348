import React, { useState } from "react";
import Header from "./header";
import Footer from "./footer";
import WebsiteBanner from "./WebsiteBanner";
import SuspenseHelper from "./SuspenseHelper";
import { useEffect } from "react";
import "../styles/global.css";
import { motion, AnimatePresence } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";

export default function Layout({ children, location, containerClassName }) {
  const data = useStaticQuery(graphql`
    query BannerQuery {
      prismicHomepage {
        data {
          body {
            ... on PrismicHomepageDataBodyWebsiteBanner {
              id
              primary {
                text_banner {
                  richText
                }
                is_displayed
              }
              slice_type
            }
          }
        }
      }
    }
  `);
  const variants = {
    initial: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.3 },
    },
  };
  const doc = data.prismicHomepage.data;
  const initialDisplay = doc.body["0"].primary.isDisplayed;
  const [isBanner, setIsBannerOpen] = useState(initialDisplay);
  const [isSolutionPage, setIsSolutionPage] = useState(location.pathname.includes("/solution"));
  useEffect(() => {
    if (location.pathname.includes("/solution")) {
      setIsSolutionPage(true);
    } else {
      setIsSolutionPage(false);
    }
  }, [location.pathname]);
  return (
    <div>
      <WebsiteBanner
        data={doc}
        isBanner={isBanner}
        setIsBannerOpen={setIsBannerOpen}
      />
      <>
        <Header
          isBanner={isBanner}
          isSolution={isSolutionPage}
          location={location}
        />
        <SuspenseHelper fallback={<main>{children}</main>}>
          <AnimatePresence>
            <motion.main
              key={location.pathname}
              variants={variants}
              initial="initial"
              animate="enter"
              exit="exit"
            >
              <div>{children}</div>
            </motion.main>
          </AnimatePresence>
        </SuspenseHelper>
        <Footer />
      </>
    </div>
  );
}
