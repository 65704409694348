import { Suspense } from "react";
import React, { useEffect, useState } from "react";

export default function SuspenseHelper({ fallback, children }) {
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    if (!isMounted) {
      setMounted(true);
    }
  });

  return (
    <Suspense fallback={fallback}>{!isMounted ? fallback : children}</Suspense>
  );
}
