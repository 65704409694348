import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import React from "react";
import LinkedinCircle from "../components/svg/LinkedinCircle";
import Logo from "../components/svg/logo";
import TwitterCircle from "../components/svg/TwitterCircle";
import {
  motion,
  useMotionTemplate,
  useScroll,
  useTransform,
} from "framer-motion";

export default function Footer() {
  const refScroll = React.useRef(null);
  const { scrollYProgress } = useScroll({
    target: refScroll,
    offset: ["start end", "start start"],
  });

  let animateSmile = useTransform(scrollYProgress, [0, 1], [10, 90]);
  let smile = useMotionTemplate`ellipse(55% ${animateSmile}% at center top)`;

  return (
    <div className="bg-blue-light relative">
      <motion.div ref={refScroll}
        style={{
          clipPath: smile,
        }} className="bg-white h-16 md:h-32 absolute top-0 right-0 left-0"></motion.div>
      <div className="max-w-6xl mx-auto lg:pb-24 pt-20 pb-10 mt-4 md:mt-20 text-blue-dark">
        <div className="md:mb-12"></div>
        <div className="flex flex-col md:flex-row px-6 md:px-8 relative z-10 pt-12">
          <div className="mr-auto mb-4 md:mb-0">
            <Link to="/" aria-label="Bankjoy Logo">
              <div className="mb-1">
                <Logo width={110} />
              </div>
            </Link>
            <div className="flex space-x-2">
              <a
                href="https://www.linkedin.com/company/bankjoy"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
              >
                <LinkedinCircle />
              </a>
              <a
                href="https://twitter.com/bankjoy"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Twitter"
              >
                <TwitterCircle />
              </a>
            </div>
          </div>
          <div className="flex">
            <div className="flex flex-col mb-4 md:mb-0">
              <div className="text-sm mb-2 font-medium">Company</div>
              <Link
                to="/"
                className="text-sm mb-1 hover:text-blue transition-colors"
              >
                Homepage
              </Link>
              <Link
                to="/blog"
                className="text-sm mb-1 hover:text-blue transition-colors"
              >
                Blog
              </Link>
              <Link
                to="/press"
                className="text-sm mb-1 hover:text-blue transition-colors"
              >
                Press
              </Link>
              <Link
                to="/people"
                className="text-sm mb-1 hover:text-blue transition-colors"
              >
                People
              </Link>
              <Link
                to="/jobs"
                className="text-sm mb-1 hover:text-blue transition-colors"
              >
                Jobs
              </Link>
            </div>
            <div className="flex flex-col ml-10 mr-3">
              <div className="text-sm mb-2 font-medium">Actions</div>
              <Link
                to="/contact"
                className="text-sm mr-3 mb-1 hover:text-blue transition-colors"
              >
                Contact Us
              </Link>
              <OutboundLink
                href="https://bankjoy.force.com/joynet/s/"
                target="_blank"
                rel="noopener"
                className="text-sm mr-3 mb-1 hover:text-blue transition-colors"
              >
                Client Login
              </OutboundLink>
              <Link
                to="/subscribe"
                className="text-sm mr-3 mb-1 hover:text-blue transition-colors"
              >
                Subscribe
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>




  );
}
