import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import Logo from "./svg/logo";
import { motion, AnimatePresence } from "framer-motion";
import { Slant as Hamburger } from "hamburger-react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { Popover, Transition } from "@headlessui/react";
import { useFloating } from "@floating-ui/react-dom";
import { ChevronRightIcon } from "@heroicons/react/solid";

function SolutionsPopover() {
  const { x, y, reference, floating, strategy } = useFloating();
  return (
    <Popover className="text-xs mr-2">
      {({ open }) => (
        <>
          <Popover.Button
            className="hover:text-blue transition-color duration-300 flex items-center focus:outline-none outline-none p-1 rounded-md"
            ref={reference}
          >
            <span className="font-medium text-xs">Solutions</span>
            <ChevronRightIcon
              className={`${
                open
                  ? "-rotate-90 w-4 mt-1 h-4 transform transition-transform duration-300"
                  : "w-4 mt-1 h-4 rotate-90 transform transition-transform duration-300"
              }`}
            />
          </Popover.Button>

          <Transition
            enter="transition"
            enterFrom="transform opacity-0"
            enterTo="transform opacity-100 "
            leave="transition ease-in "
            leaveFrom="transform opacity-100"
            leaveTo="transform opacity-0"
          >
            <Popover.Panel
              ref={floating}
              className="flex mt-7 flex-col w-max bg-white px-6 py-4 rounded-lg shadow-3xl"
              style={{
                position: strategy,
                top: y ?? 20,
                left: x ?? 0,
              }}
            >
              {({ close }) => (
                <button
                  onClick={() => close()}
                  className="flex flex-col font-medium"
                >
                  <Link
                    to="/solution/retail-banking"
                    className="hover:bg-blue-lite rounded-md py-1 px-1.5 -ml-1.5 text-xs hover:text-blue transition-colors "
                  >
                    Retail Banking
                  </Link>
                  <a
                    rel="noopener noreferrer"
                    href="https://pages.bankjoy.com/business-banking"
                    target="_blank"
                    className="hover:bg-blue-lite rounded-md py-1 px-1.5 -ml-1.5 text-xs hover:text-blue transition-colors "
                  >
                    Business Banking
                  </a>
                  <Link
                    to="/solution/online-loan-application"
                    className="hover:bg-blue-lite rounded-md py-1 px-1.5 -ml-1.5 text-xs hover:text-blue transition-colors "
                  >
                    Online Loan Applications
                  </Link>
                  <a
                    rel="noopener noreferrer"
                    href="https://pages.bankjoy.com/online-account-opening-2.0"
                    target="_blank"
                    className="hover:bg-blue-lite rounded-md py-1 px-1.5 -ml-1.5 text-xs hover:text-blue transition-colors "
                  >
                    Online Account Opening
                  </a>
                  <Link
                    to="/solution/conversational-ai"
                    className="hover:bg-blue-lite rounded-md py-1 px-1.5 -ml-1.5 text-xs hover:text-blue transition-colors "
                  >
                    Conversational AI and Phone Banking
                  </Link>
                  <Link
                    to="/solution/statements"
                    className="hover:bg-blue-lite rounded-md py-1 px-1.5 -ml-1.5 text-xs hover:text-blue transition-colors"
                  >
                    Statements
                  </Link>
                  <Link
                    to="/solution/security"
                    className="hover:bg-blue-lite rounded-md py-1 px-1.5 -ml-1.5 text-xs hover:text-blue transition-colors "
                  >
                    Security
                  </Link>
                </button>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

function ResourcesPopover() {
  const { x, y, reference, floating, strategy } = useFloating();
  return (
    <Popover className="text-xs mr-2">
      {({ open }) => (
        <>
          <Popover.Button
            className="hover:text-blue transition-color duration-300 flex items-center focus:outline-none outline-none p-1 rounded-md"
            ref={reference}
          >
            <span className="font-medium text-xs">Resources</span>
            <ChevronRightIcon
              className={`${
                open
                  ? "-rotate-90 w-4 mt-1 h-4 transform transition-transform duration-300"
                  : "w-4 mt-1 h-4 rotate-90 transform transition-transform duration-300"
              }`}
            />
          </Popover.Button>

          <Transition
            enter="transition"
            enterFrom="transform opacity-0"
            enterTo="transform opacity-100 "
            leave="transition ease-in "
            leaveFrom="transform opacity-100"
            leaveTo="transform opacity-0"
          >
            <Popover.Panel
              ref={floating}
              className="flex mt-7 flex-col w-max bg-white px-6 py-4 rounded-lg shadow-3xl"
              style={{
                position: strategy,
                top: y ?? 20,
                left: x ?? 0,
              }}
            >
              {({ close }) => (
                <button
                  onClick={() => close()}
                  className="flex flex-col font-medium"
                >
                  <Link
                    to="/blog"
                    className="hover:bg-blue-lite rounded-md py-1 px-1.5 -ml-1.5 text-xs hover:text-blue transition-colors"
                  >
                    Blog
                  </Link>
                  <Link
                    to="/case-studies"
                    className="hover:bg-blue-lite rounded-md py-1 px-1.5 -ml-1.5 text-xs hover:text-blue transition-colors"
                  >
                    Case Studies
                  </Link>
                  <Link
                    to="/press"
                    className="hover:bg-blue-lite rounded-md py-1 px-1.5 -ml-1.5 text-xs hover:text-blue transition-colors"
                  >
                    News
                  </Link>
                  <Link
                    to="/events"
                    className="hover:bg-blue-lite rounded-md py-1 px-1.5 -ml-1.5 text-xs hover:text-blue transition-colors"
                  >
                    Events & Webinars
                  </Link>
                </button>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

function Menu({ isOpen, setIsOpen }) {
  const variants = {
    show: {
      opacity: 1,
      transition: { when: "beforeChildren", duration: 0.3 },
    },
    hide: {
      opacity: 0,
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.6,
        when: "afterChildren",
      },
    },
  };

  const childVariants = {
    show: {
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
    hide: {
      opacity: 0,
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.4,
      },
    },
    toRight: {
      y: 5,
      transition: {
        duration: 0.3,
        ease: "easeOut",
      },
    },
  };
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          variants={variants}
          animate={isOpen ? "show" : "hide"}
          initial="hide"
          exit="exit"
          className="fixed top-0 left-0 right-0 bottom-0 w-full bg-white z-30"
        >
          <motion.div className="flex" variants={childVariants}>
            <div className="flex flex-col mt-6  md:justify-center ml-12 h-screen">
              <h5>Solutions</h5>
              <motion.div variants={childVariants} className="">
                <Link
                  to="/solution/retail-banking"
                  className="font-medium text-lg"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Retail Banking
                </Link>
              </motion.div>
              <motion.div variants={childVariants} className="">
                <a
                  rel="noopener noreferrer"
                  href="https://pages.bankjoy.com/business-banking"
                  className="font-medium text-lg"
                  target="_blank"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Business Banking
                </a>
              </motion.div>
              <motion.div variants={childVariants} className="">
                <Link
                  to="/solution/online-loan-application"
                  className="font-medium text-lg"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Online Loan Applications
                </Link>
              </motion.div>
              <motion.div variants={childVariants} className="">
                <a
                  rel="noopener noreferrer"
                  href="https://pages.bankjoy.com/online-account-opening-2.0"
                  className="font-medium text-lg"
                  target="_blank"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Online Account Opening
                </a>
              </motion.div>
              <motion.div variants={childVariants} className="">
                <Link
                  to="/solution/conversational-ai"
                  className="font-medium text-lg"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Conversational AI and Phone Banking
                </Link>
              </motion.div>

              <motion.div variants={childVariants} className="">
                <Link
                  to="/solution/statements"
                  className="font-medium text-lg"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Statements
                </Link>
              </motion.div>
              <motion.div variants={childVariants} className="">
                <Link
                  to="/solution/security"
                  className="font-medium text-lg"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Security
                </Link>
              </motion.div>
              <h5 className="mt-4">Resources</h5>
              <motion.div variants={childVariants} className="">
                <Link
                  to="/blog"
                  className="font-medium text-lg"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Blog
                </Link>
              </motion.div>
              <motion.div variants={childVariants} className="">
                <Link
                  to="/case-studies"
                  className="font-medium text-lg"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Case Studies
                </Link>
              </motion.div>
              <motion.div variants={childVariants} className="">
                <Link
                  to="/press"
                  className="font-medium text-lg"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  News
                </Link>
              </motion.div>
              <motion.div variants={childVariants} className="">
                <Link
                  to="/events"
                  className="font-medium text-lg"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Events & Webinars
                </Link>
              </motion.div>
              <h5 className="mt-4">About Us</h5>
              <motion.div variants={childVariants} className="">
                <Link
                  to="/people"
                  className="font-medium text-lg"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  People
                </Link>
              </motion.div>
              <motion.div variants={childVariants} className="mb-8">
                <Link
                  to="/jobs"
                  className="font-medium text-lg"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Careers
                </Link>
              </motion.div>
              <motion.div variants={childVariants} className="text-lg mb-4">
                <OutboundLink
                  href="https://bankjoy.force.com/joynet/s/"
                  target="_blank"
                  rel="noopener"
                  className="font-medium w-max text-lg block "
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Client Login
                </OutboundLink>
              </motion.div>
              <motion.div variants={childVariants}>
                <Link
                  to="/subscribe"
                  onClick={() => setIsOpen(!isOpen)}
                  className="hoverShadow mb-4 text-lg w-max block rounded-full -ml-.5 bg-white border-blue border-2 text-blue uppercase pb-2 pt-1.5 px-4"
                >
                  Subscribe
                </Link>
              </motion.div>
              <motion.div variants={childVariants}>
                <Link
                  to="/contact"
                  onClick={() => setIsOpen(!isOpen)}
                  className="hoverShadow  text-lg w-max block rounded-full -ml-.5 bg-blue text-white pb-2 pt-1.5 px-4"
                >
                  SCHEDULE A DEMO
                </Link>
              </motion.div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

const ScheduleDemo = () => {
  return (
    <motion.div>
      <Link
        to="/contact"
        className="hoverShadow border-2 border-blue uppercase transition-all inline-block text-xs rounded-md bg-blue text-white pb-2 pt-1.5 px-3"
      >
        Schedule Demo
      </Link>
    </motion.div>
  );
};

function ContactUs ({location}){
  function renderSwitch(location) {
    switch(location.pathname) {
      case '/solution/retail-banking/':
        return '/contact/?referring_page=retail_banking';
      case '/solution/online-loan-application/':
        return '/contact/?referring_page=loan_app';
      case '/solution/conversational-ai/':
        return '/contact/?referring_page=conversational_ai';
      case '/solution/statements/':
        return '/contact/?referring_page=statements';
      case '/solution/security/':
        return '/contact/?referring_page=security';
      default:
        return '/contact/';
    }
  }
  return (
    <motion.div layout>
      <Link
        to={renderSwitch(location)}
        className="hoverShadow uppercase transition-all inline-block text-xs rounded-md bg-blue text-white pb-2 pt-1.5 px-3"
      >
        Sign up for a demo
      </Link>
    </motion.div>
  );
};

export default function Header({ isSolution, isBanner, location }) {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isOpen]);
  
  return (
    <React.Fragment>
      <div className={isBanner ? "sticky transition-all duration-500 px-3 md:pl-6 mx-auto top-8 z-40": "sticky transition-all duration-500 px-3 md:pl-6 mx-auto top-4 z-40"}>
        <div className="max-w-6xl bg-white mb-8 md:mb-10 items-center pr-6 pl-4 py-4 rounded-xl mx-auto flex font-medium ">
          <motion.div  className="relative z-10 ml-2 mr-auto">
            <Link to="/" aria-label="Bankjoy Logo">
              <Logo width={100} />
            </Link>
          </motion.div>
          <motion.div  className="hidden -mr-2 md:items-center md:flex">
            <Popover.Group className="flex flex-row items-center">
              <SolutionsPopover />
              <ResourcesPopover />
            </Popover.Group>
            <Link
              to="/people"
              className="text-xs mr-4 hover:text-blue transition-colors duration-300"
            >
              About Us
            </Link>
            <Link
              to="/jobs"
              className="text-xs mr-4 hover:text-blue transition-colors duration-300"
            >
              Careers
            </Link>
            <OutboundLink
              className="text-xs mr-4 hoverBlue"
              href="https://bankjoy.force.com/joynet/s/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Client Login
            </OutboundLink>
            <Link
              className="text-xs mr-4 border-blue box-content border-2  hoverShadow px-3 pb-2 pt-1.5 uppercase text-blue rounded-md hoverBlue"
              to="/subscribe"
            >
              Subscribe
            </Link>
            <AnimatePresence>
              {isSolution ? <ContactUs location={location} /> : <ScheduleDemo />}
            </AnimatePresence>
          </motion.div>
          <Menu isOpen={isOpen} setIsOpen={setIsOpen} />
          <button
            aria-label="Show menu"
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="mt-1 md:hidden"
          >
            <div className={isOpen ? "z-50" : ""}>
              <Hamburger toggled={isOpen} toggle={setIsOpen} size={24} />
            </div>
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
