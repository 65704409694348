import React from "react"

const Logo = ({width, color}) => (
<div style={{width: width}}>
  <svg fill={color || "#027AFF"  } width="100%" viewBox="0 0 1216 354">
      <path d="M130.2 121.4c27.5-5.2 45.4-23.5 45.4-54.4 0-38.5-29.1-62.2-76.2-62.2H9c-4 0-7 3-7 7V236c0 4 3 7 7 7h94.1c47.1 0 75.7-25.1 75.7-66.1.1-32.8-21.1-52.7-48.6-55.5zM56.8 44.7h30.5c21.2 0 33.6 10.8 33.6 28.9 0 18.1-12.6 28.9-33.6 28.9H56.8V44.7zM91 201.4H56.8v-60.5H91c19.6 0 32.9 11.5 32.9 29.3 0 19.7-12.6 31.2-32.9 31.2zM364.8 79.2h-42.2c-2.6 0-4.7 2.1-4.7 4.7v18.8c-8.9-16.7-27.5-27.9-51-27.9-47.3 0-77.4 37.5-77.4 86.6 0 49.3 28 86.6 76.9 86.6 22.6 0 42.9-11.3 51.7-27.7v18.5c0 2.6 2.1 4.7 4.7 4.7H365c2.6 0 4.7-2.1 4.7-4.7v-155c-.2-2.5-2.3-4.6-4.9-4.6zm-83.6 125.2c-24 0-38.9-18.3-38.9-43.2s14.9-42.9 38.9-42.9c25.6 0 38.9 19.5 38.9 42.9 0 23.7-13.3 43.2-38.9 43.2zM507 74.7c-24.7 0-43.3 9.9-52.7 28.1V83.6c0-2.6-2.1-4.7-4.7-4.7h-42.4c-2.6 0-4.7 2.1-4.7 4.7v154.8c0 2.6 2.1 4.7 4.7 4.7h42.4c2.6 0 4.7-2.1 4.7-4.7v-80.9c0-25.1 10.7-38.7 31.5-38.7 21 0 28.7 12.7 28.7 40.1v79.8c0 2.6 2.1 4.7 4.7 4.7H562c2.6 0 4.7-2.1 4.7-4.7v-96.9c-.1-44.6-20.1-67.1-59.7-67.1zm254.9 164.9l-63.2-82.8 61.8-73.9c1.2-1.6.2-3.8-1.9-3.8H705c-1.4 0-2.8.7-3.7 1.9l-51.5 66.6V9.5c0-2.6-2.1-4.7-4.7-4.7h-42.4c-2.6 0-4.7 2.1-4.7 4.7v228.9c0 2.6 2.1 4.7 4.7 4.7h42.4c2.6 0 4.7-2.1 4.7-4.7V172l50.3 69.2c.9 1.2 2.3 1.9 3.7 1.9H760c2.2.2 3.1-2.1 1.9-3.5zM809.9.8c-18.6 0-32.2 14.5-32.2 31.9 0 17.6 13.5 31.9 32.2 31.9 18.9 0 32.2-14.3 32.2-31.9 0-17.3-13.3-31.9-32.2-31.9z"></path>
      <path d="M829.5 81c-13.3 3.8-26.6 3.8-39.8 0-3-.9-6.1 1.4-6.1 4.5v170.1c0 10.8-4.4 15.7-13.3 15.7-2.8 0-4.9 0-7.2-.5-2.8-.5-5.4 1.9-5.4 4.7v31.2c0 2.1 1.4 4 3.3 4.5 5.8 1.6 11.9 2.3 21.4 2.3 33.8 0 52.7-17.1 52.7-51.4V85.5c.5-3.1-2.6-5.2-5.6-4.5zm116.7 167.3c55.9 0 90.4-37.1 90.4-87.3 0-50-34.5-86.8-90.4-86.8-56.2 0-90.7 36.8-90.7 86.8.1 50.2 34.6 87.3 90.7 87.3zm0-130c24.5 0 38.9 18.1 38.9 42.9 0 24.9-14.4 42.9-38.9 42.9-24.7 0-38.9-18.1-38.9-42.9 0-24.8 14.2-42.9 38.9-42.9z"></path>
      <path d="M1209.3 79.2h-43.8c-2.1 0-3.7 1.4-4.4 3.3l-36.8 114-40.5-114c-.7-1.9-2.3-3-4.4-3h-43.6c-3.3 0-5.6 3.3-4.2 6.6l66.9 161.4c-11.9 25.3-33.8 44.1-62.9 52.8-43.3 13.1-90.9 1.9-125.1-29.6-2.8-2.6-7-2.3-9.6.2L877.7 294c-2.8 2.8-2.8 7.5.2 10.1 33.8 31.4 77.1 48.3 121.2 48.3 61.1 0 122.1-34.5 145.9-92.4l69.2-174.3c.5-3.3-1.6-6.5-4.9-6.5z"></path>
  </svg>
  </div>
)

export default Logo